import { loginRequest } from '../authConfig';
import msalInstance from '../msalInstance';
export const getToken = () => {

    const account = msalInstance.getActiveAccount();

    if (!account) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    const acquireTokenSilent = async () => {
        const { idToken } = await msalInstance.acquireTokenSilent({
            account,
            ...loginRequest,
        });
        return idToken;
    };

    return acquireTokenSilent()
        .then(tokenSilent => {
            return tokenSilent;
        })
        .catch(error => {
            console.error('acquireTokenSilent failed:', error);
            throw error;
        });
};
