import { useEffect, useState } from "react";
import { getToken } from "../utils/getToken";
import axios from "axios";


const Checklist = () => {
    const [authToken, setAuthToken] = useState(null);
    const [checkList, setcheckList] = useState(null);
    const handleCheckboxChange = (checkbox) => {
        const updatedCheckList = checkList.map(item => {
            if (item.id === checkbox.id) {
                return { ...item, status: !checkbox.status };
            }
            return item;
        });

        setcheckList(updatedCheckList); // Update the state to trigger a re-render

        

        axios
            .put(`${process.env.REACT_APP_API_URL}/checklist`, {
                id: checkbox.id,
                status: !checkbox.status
            }, {

                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .catch((error) => {
                console.error("GET Request Error:", error);
            });
    }


    useEffect(() => {
        console.log("first");

        const fetchToken = async () => {
            try {
                const token = await getToken();
                setAuthToken(token);

            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };

        if (!authToken) {
            fetchToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (authToken !== null) {

            axios
                .get(`${process.env.REACT_APP_API_URL}/checklist`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                })
                .then((response) => {

                    
                    setcheckList(response.data.data);
                    console.log(checkList);
                })
                .catch((error) => {
                    console.error("GET Request Error:", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);



    return (
        <div className="extra-margin">
            <h2>Checklist</h2>
            <section className="checklist-container">
                {checkList?.map((checkbox, index) => {
                    return (
                        <label key={index} className="checklist-container__checkbox">
                            <div dangerouslySetInnerHTML={{ __html: checkbox.label }} />
                            <input type="checkbox"
                                checked={checkbox.status === 1 || checkbox.status}
                                id={checkbox.id}
                                value={checkbox.value}

                                onChange={() => handleCheckboxChange(checkbox)}

                            
                            />
                            <span className="checkbox-custom"></span>
                        </label>
                    );
                })}


            </section>
        </div>
    )


};

export default Checklist;
