/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../utils/getToken";
import msalInstance from "../msalInstance";

const LoginKetch2 = (props) => {
  const [authToken, setAuthToken] = useState(null);


  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getToken();
        setAuthToken(token);
    
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    // Call the fetchToken function
    if(!authToken) {
      fetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(authToken !== null){

      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          const userData = response.data;
          // Set the user data using the useState hook
          props.setUserData(userData);
        })
        .catch((error) => {
          console.error("GET Request Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    if (authToken !== null) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/contacts`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          const contactsData = response.data;
          props.setContactsData(contactsData);
        })
        .catch((error) => {
          console.error("GET Request Error:", error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

    return (
      <div>
        <section className="profile" id="profiel">
          {/* <section className="main"> */}
            <h2> 
              <span>Welkom {props?.userData?.data?.firstName} {props?.userData?.data?.lastName}! </span>
            <span title="Log uit" className="btn-logout" onClick={() => msalInstance.logoutRedirect()}
><i className="ri-logout-box-r-line"></i></span>
            </h2>
            {/* <h2> Welkom {props.userData.data.firstName} {props.userData.data.lastName}!</h2> */}
            {/* <img src={`data:image/eng;base64,${img.data}`} className="profilepic" /> */}
          {/* </section> */}
          {/* <section className="coce">
            <p>Jouw contacten:</p>
            {contactsData.status === 'success' ? contactsData?.data?.slice(0, 4).map((contact, index) => (
              <section key={contact.id}>
                <img src={`data:image/eng;base64,${thumbnails.data[index]}`} className="profilepic" />
                <h2>{contact.firstName} {contact.lastName}</h2>
                <p>{contact.type}</p>
                <p>
                  <a href={`mailto: ${contact.email}`}>{contact.email}</a>
                </p>
                <p>{contact.telephone.number}</p>
              </section>
            )) : null}
          </section> */}
        </section>
        {/* Other JSX components using userData */}
      </div>
    );
 
};

export default LoginKetch2;
