import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './mainStyle.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import msalInstance from './msalInstance';
import { MsalProvider } from '@azure/msal-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,

);

reportWebVitals();
