import React, { useState } from "react";
import Topnav from './components/Topnav';
import LoginKetch2 from './components/LoginKetch2';
import Main from './components/Main';
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';

const App = () => {
  useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();

  const [userData, setUserData] = useState(null);
  const [contactsData, setContactsData] = useState(null);
  
  return (

    <div>
      {isAuthenticated
        && (
          <><Topnav /><div className="container">
            <div className="content">

              {/* <LoginKetch2/>
              <Main /> */}
              <LoginKetch2 
                userData={userData} setUserData={setUserData}  
                contactsData={contactsData} setContactsData={setContactsData}
                />
              <Main contactsData={contactsData}/>

            </div>
          </div></>
        )}
    </div>
  );
}

export default App;
