import { useState } from "react";

function Topnav() {

    const [visible, setVisible] = useState(false);

    function showNav(fixed=null) {
        console.log(fixed);
        if(fixed) {
            setVisible(fixed)
        } else {
            setVisible(!visible);
        }
    }
    let returnedElem = (

        <div onScroll={() => this.showNav(false)}>
            <div className="topnav">
                <div id="toplogo">
                    <img src="./img/UPlogo.SVG" alt="logo" />
                    <div className="introText">
                        <h1>Wie is wie?</h1>
                        <p>Via deze pagina proberen we je wegwijs te maken bij UniPartners</p>
                    </div>
                </div>
                <div id="topnavigation">
                    <span className="burgericon" onClick={()=>showNav()}><i className="ri-menu-line"></i></span>
                    <div className={visible ? "visible nav-stepper" : "notvisible nav-stepper"}>
                        <a onClick={()=>showNav()} href="#begin" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-door-open-line"></i></span>
                            <h3 className="nav-stepper__title">Voorbereiding op je eerste werkdag</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#eerstedag" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-pencil-line"></i></span>
                            <h3 className="nav-stepper__title">Eerste werkdag</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#bank" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-home-line" /></span>
                            <h3 className="nav-stepper__title">Op de bank</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#project" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-building-line"></i></span>
                            <h3 className="nav-stepper__title">Op project</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#project" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-file-list-line"></i></span>
                            <h3 className="nav-stepper__title">Timesheet</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#project" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-capsule-fill"></i></span>
                            <h3 className="nav-stepper__title">Ziekte</h3>
                        </a>
                        <a onClick={()=>showNav()} href="#praktisch" className="nav-stepper__item">
                            <span className="nav-stepper__circle"><i className="ri-car-line"></i></span>
                            <h3 className="nav-stepper__title">Schade</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
    return returnedElem
}
export default Topnav;